import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";

const Page = ({ data }) => {
   return (
      <Layout>
         <SearchEngineOptimization
            title="Reviews | Conscious Legal | Estate Planning Law Firm"
            description="Learn why Conscious Legal is a top estate planning law firm in San Diego. Read reviews from satisfied customers. Schedule a consultation!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <section className="bg-primary-50 pt-16 md:pt-24 pb-22 md:pb-32">
            <div className="container">
               <div className="max-w-3xl mx-auto p-8 md:p-12 lg:p-18 bg-white shadow-3xl">
                  <iframe src="https://conscious-legal-reviews-platform.flywheelsites.com/" title="Reviews Platform" width="100%" height="1200px" />
               </div>
            </div>
         </section>
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Reviews_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Reviews_TW.jpg" }) {
         publicURL
      }
   }
`;

export default Page;
